import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/Layout'
import Hero from '../components/Hero'

/**
 * Create Page Template Component
 */
const PageTemplate = (props) => {
  console.log(props)

  return (
    <Layout
      className={`is-page-${props.pageIdentifier || 'unlabelled'} ${props.pageClass || ''}`}
      title={props.title}
      seoTitle={props.seoTitle}
      seoDescription={props.seoDescription}
      heroImage={props.image}
      showContactForm={props.showContactForm}
    >
      <Hero
        aboveTitle={props.aboveTitle}
        title={props.title}
        titleContinuation={props.titleContinuation}
        subtitle={props.subtitle}
        image={props.image}
        showCTA={props.showCTA}
        imageAlt={props.imageAlt || props.title}
        CTAUrl={props.CTAUrl}
        CTAText={props.CTAText}
        CTAUrl2={props.CTAUrl2}
        CTAText2={props.CTAText2}
        showCTA2={props.showCTA2}
      />
      <div className="container content is-narrow">
        {/* Markdown fallback for CMS Previews */}

        {(() => {
          // If the element is used as <PageTemplate>...</PageTemplate> instead of <PageTemplate />, show children
          if (props.children) {
            return props.children
          }
          // If disableMdx is set, return normal body (for CMS Previews)
          else if (props.disableMdx === true || props.disableMdx === 'true') {
            return props.body
          }
          // Otherwise return the MDX Rendered body
          else if (props.body && props.body.length) {
            return <MDXRenderer>{props.body}</MDXRenderer>
          } else {
            return false
          }
        })()}
      </div>
    </Layout>
  )
}

export { PageTemplate }

/**
 * Fill Page Component with GraphQL Data
 */
const Page = ({ data }) => {
  return (
    <PageTemplate
      aboveTitle={data.mdx.frontmatter.aboveTitle}
      title={data.mdx.frontmatter.title}
      titleContinuation={data.mdx.frontmatter.titleContinuation}
      pageIdentifier={data.mdx.fields.pageIdentifier}
      seoTitle={data.mdx.frontmatter.seoTitle || ''}
      seoDescription={data.mdx.frontmatter.seoDescription | ''}
      pageClass={data.mdx.frontmatter.pageClass}
      subtitle={data.mdx.frontmatter.subtitle}
      image={data.mdx.fields.image}
      imageAlt={data.mdx.frontmatter.imageAlt}
      showCTA={data.mdx.frontmatter.showCTA}
      showContactForm={data.mdx.frontmatter.showContactForm}
      body={data.mdx.body}
      CTAUrl={data.mdx.frontmatter.CTAUrl}
      CTAText={data.mdx.frontmatter.CTAText}
      CTAUrl2={data.mdx.frontmatter.CTAUrl2}
      CTAText2={data.mdx.frontmatter.CTAText2}
      showCTA2={data.mdx.frontmatter.showCTA2}
    />
  )
}

export default Page

export const post = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        aboveTitle
        title
        titleContinuation
        pageClass
        templateKey
        showCTA
        showContactForm
        subtitle
        seoTitle
        seoDescription
        imageAlt
        CTAUrl
        CTAText
        CTAUrl2
        CTAText2
        showCTA2
      }
      fields {
        pageIdentifier
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
